import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChevronIcon, colors, Button, ClearIcon } from "@commonsku/styles";
import Filter from "./Filter";
import { useReportContext } from "../report_context";
import {
  ReportFilterConfig,
  FILTER_STYLE_CONSTANTS,
} from "./config/ReportFilterConfig";
import { STYLE_CONSTANTS } from "../config";
import { ReportFilterValues } from "./config/types";
import {
  BtnText,
  BtnsContainer,
  FiltersContainer,
  FiltersItems,
  GetReportButton,
  MoreFiltersBtnContainer,
  MoreFiltersButton,
  MoreFiltersContainer,
  MoreFiltersDummySpace,
} from "./StyledComponents";
import { throttle } from "lodash";

const {
  SINGLE_BTN_AREA_WIDTH,
  MORE_FILTERS_BTN_WIDTH,
  FILTER_ITEMS_GAP,
  BTNS_GAP,
} = FILTER_STYLE_CONSTANTS;
const { SIDE_BAR_WIDTH } = STYLE_CONSTANTS;

const ReportFiltersContainer = ({ className }: { className?: string }) => {
  const [moreFiltersOpen, setMoreFiltersOpen] = useState<boolean>(false);
  const [regularFilters, setRegularFilters] = useState<ReportFilterConfig[]>(
    [],
  );
  const [moreFilters, setMoreFilters] = useState<ReportFilterConfig[]>([]);
  const [shouldRenderMoreFilters, setShouldRenderMoreFilters] =
    useState<boolean>(false);
  const [itemWidth, setItemWidth] = useState({});
  const moreFiltersContainerRef = useRef<HTMLDivElement>();
  const moreFiltersBtnRef = useRef<HTMLButtonElement>();
  const {
    reportConfig: { filters, refetchOnFilterChange },
    query,
    onFilterChange,
    fetchReport,
    resetQuery,
    elementSizeParameters: { FILTER_CONTAINER_HEIGHT },
  } = useReportContext();

  const moreFiltersClearable: boolean = useMemo(() => {
    if (!shouldRenderMoreFilters) {
      return false;
    }
    const subquery = filters.getSubQuery(query, moreFilters);
    return filters.filterConfigs
      .filter((filter) =>
        Array.isArray(filter.fields)
          ? filter.fields.some((field) => field in subquery)
          : filter.fields in subquery,
      )
      .some((filter) =>
        Array.isArray(filter.fields)
          ? filter.fields.some(
              (field) => subquery[field] !== (filter.defaultValue ?? ""),
            )
          : subquery[filter.fields] !== (filter.defaultValue ?? ""),
      );
  }, [filters, moreFilters, query, shouldRenderMoreFilters]);

  useEffect(() => {
    filters.filterConfigs.forEach((filter: ReportFilterConfig) => {
      setItemWidth((widths) => ({
        ...widths,
        [filter.id]: { width: filter.styleProps.flexBasis },
      }));
    });
  }, [filters]);

  const calculateAvailableWidth = () => {
    return (
      window.innerWidth -
      SIDE_BAR_WIDTH -
      SINGLE_BTN_AREA_WIDTH -
      FILTER_ITEMS_GAP
    );
  };

  const distributeFilters = useCallback(
    (containerWidth: number) => {
      const containerWidthWithMoreFiltersBtn =
        containerWidth - MORE_FILTERS_BTN_WIDTH - BTNS_GAP;
      let currentWidth = 0;
      const regularFilters = [];
      const moreFilters = [];

      filters.filterConfigs.forEach((filter, index) => {
        const filterWidth =
          itemWidth[filter.id]?.width || filter.styleProps.flexBasis || 0;
        const newWidth = currentWidth + filterWidth + FILTER_ITEMS_GAP;

        if (newWidth <= containerWidthWithMoreFiltersBtn || index === 0) {
          adjustFilterStyle(
            filter,
            index,
            containerWidthWithMoreFiltersBtn,
            filterWidth,
          );
          regularFilters.push(filter);
          currentWidth = newWidth;
        } else {
          moreFilters.push(filter);
        }
      });

      return { regularFilters, moreFilters };
    },
    [filters, itemWidth],
  );

  const adjustFilterStyle = (
    filter: ReportFilterConfig,
    index: number,
    maxWidth: number,
    filterWidth: number,
  ) => {
    if (index === 0 && filterWidth >= maxWidth) {
      filter.styleProps = {
        ...filter.styleProps,
        flexBasis: maxWidth - FILTER_ITEMS_GAP,
        flexGrow: 1,
        flexShrink: 0,
      };
    }
  };

  useLayoutEffect(() => {
    const updateFiltersVisibility = throttle(() => {
      const containerWidth = calculateAvailableWidth();
      const allFiltersWidth = filters.getFiltersTotalWidth();
      const shouldRenderMoreFilters = allFiltersWidth > containerWidth;

      setShouldRenderMoreFilters(shouldRenderMoreFilters);

      if (!shouldRenderMoreFilters) {
        setRegularFilters(filters.filterConfigs);
        setMoreFilters([]);
      } else {
        const { regularFilters, moreFilters } =
          distributeFilters(containerWidth);
        setRegularFilters(regularFilters);
        setMoreFilters(moreFilters);
      }
    }, 100);

    updateFiltersVisibility();

    window.addEventListener("resize", updateFiltersVisibility);

    return () => window.removeEventListener("resize", updateFiltersVisibility);
  }, [distributeFilters, filters, itemWidth]);

  const createFilterComponent = useCallback(
    (
      filter: ReportFilterConfig,
      values: ReportFilterValues,
      isInMoreFilters: boolean,
      dependsOnValues?: ReportFilterValues,
    ) => (
      <Filter
        key={filter.id}
        config={filter}
        queryValues={values}
        dependsOnValues={dependsOnValues}
        isInMoreFilters={isInMoreFilters}
        onQueryChange={(values) => onFilterChange(filter.fields, values)}
      />
    ),
    [onFilterChange],
  );

  const renderFilters = useCallback(
    (filters: ReportFilterConfig[], isInMoreFilters = false) => {
      return filters.map((filter: ReportFilterConfig) => {
        const values: ReportFilterValues = Array.isArray(filter.fields)
          ? filter.fields.map((field) => query[field])
          : query[filter.fields];

        if (!filter.dependsOn) {
          return createFilterComponent(filter, values, isInMoreFilters);
        }

        const dependsOnValues = filter.dependsOn.filterFields.map(
          (field) => query[field],
        );

        let shouldRender = true;

        if (filter.dependsOn.forOccurence) {
          if (filter.dependsOn.parentValue) {
            shouldRender =
              dependsOnValues.toString() ===
              filter.dependsOn.parentValue.toString();
          } else {
            shouldRender = dependsOnValues.some(
              (value) => value !== undefined && value !== null && value !== "",
            );
          }
        }

        return shouldRender
          ? createFilterComponent(
              filter,
              values,
              isInMoreFilters,
              dependsOnValues,
            )
          : null;
      });
    },
    [createFilterComponent, query],
  );

  const renderMoreFiltersContainer = useCallback(() => {
    return (
      moreFiltersOpen && (
        <MoreFiltersContainer
          active={moreFiltersOpen}
          ref={moreFiltersContainerRef}
        >
          {renderFilters(moreFilters, true)}
        </MoreFiltersContainer>
      )
    );
  }, [moreFilters, moreFiltersContainerRef, moreFiltersOpen, renderFilters]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (!moreFiltersOpen && moreFiltersBtnRef.current?.contains(target)) {
        setMoreFiltersOpen(true);
        return;
      }

      if (
        moreFiltersOpen &&
        (!moreFiltersContainerRef.current?.contains(target) ||
          moreFiltersBtnRef.current?.contains(target))
      ) {
        setMoreFiltersOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick, { capture: true });

    return () =>
      window.removeEventListener("click", handleOutsideClick, {
        capture: true,
      });
  }, [moreFiltersOpen]);

  const clearMoreFilters = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      resetQuery(moreFilters);
    },
    [moreFilters, resetQuery],
  );

  const renderToggleMoreFiltersBtn = useCallback(
    () =>
      shouldRenderMoreFilters && (
        <MoreFiltersBtnContainer
          id="more-filters-btn-container"
          active={moreFiltersOpen}
        >
          {renderMoreFiltersContainer()}
          <MoreFiltersButton
            iconPosition="right"
            iconProps={{ color: colors.primary1.main }}
            secondary
            Icon={<ChevronIcon direction={moreFiltersOpen ? "up" : "down"} />}
            ref={moreFiltersBtnRef}
          >
            <BtnText>More Filters</BtnText>
            {moreFiltersClearable && (
              <ClearIcon
                altText="Clear More Filters"
                onClick={clearMoreFilters}
              />
            )}
          </MoreFiltersButton>
          {moreFiltersOpen && <MoreFiltersDummySpace />}
        </MoreFiltersBtnContainer>
      ),
    [
      clearMoreFilters,
      moreFiltersClearable,
      moreFiltersOpen,
      renderMoreFiltersContainer,
      shouldRenderMoreFilters,
    ],
  );

  const renderGetReportBtn = useCallback(
    () => (
      <GetReportButton
        as={Button}
        id="get-report-btn"
        onClick={() => {
          setMoreFiltersOpen(false);
          fetchReport();
        }}
      >
        <BtnText>Get Report</BtnText>
      </GetReportButton>
    ),
    [fetchReport],
  );

  return (
    <>
      <FiltersContainer className={className} height={FILTER_CONTAINER_HEIGHT}>
        <FiltersItems className="report-filter-container-style">{renderFilters(regularFilters)}</FiltersItems>
        {(shouldRenderMoreFilters || !refetchOnFilterChange) &&
          <BtnsContainer
            single={!shouldRenderMoreFilters}
            id="report-btn-container"
          >
            {renderToggleMoreFiltersBtn()}
            {!refetchOnFilterChange && renderGetReportBtn()}
          </BtnsContainer>
        }
      </FiltersContainer>
    </>
  );
};

export default ReportFiltersContainer;
